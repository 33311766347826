import { Col, Progress, Row } from 'antd';
import { useEffect, useState } from 'react';
import { answers } from '../data/data';
import style from './question-step.module.scss';

interface QuestionStepProps {
  question: string | undefined;
  percent: number;
  step: string;
  id: number;
  answers: any;
  onAnswerSelect: any;
  onLastAnswerSelect?: any;
  type: string | undefined;
}

function QuestionStep(props: QuestionStepProps) {
  const [selectedAnswer, setSelectedAnswer] = useState<number | null>(null);
  useEffect(() => {
    setSelectedAnswer(null); 
  }, [props.id]); 
  const answerList =
    props.answers && Array.isArray(props.answers)
      ? answers.filter((answer) => props.answers.includes(answer.id))
      : [];

      const handleAnswerSelect = (answerId: number) => {
        if (props.type === 'card one' && props.onLastAnswerSelect) {
          props.onLastAnswerSelect(answerId);
        } else {
          props.onAnswerSelect(answerId);
        }
        setSelectedAnswer(answerId); 
      };

  return (
    <Row gutter={[24, 24]} className={style.container}>
      <Progress
        className={style.steps}
        style={{ backgroundColor: '#fafafa', borderRadius: '50%', width: '79px', height: '79px' }}
        width={80}
        type="circle"
        percent={props.percent}
        format={() => (
          <div className={style.customProgress}>
            <span>{props.step}</span>
          </div>
        )}
        strokeColor="#735EBB"
        strokeWidth={6}
        trailColor="#fafafa"
      />
      <div className={style.questionContainer}>
        <span>{props.question}</span>
      </div>
      <div className={style.birthdayContainer}>
        <div className={style.content}>
          <Row gutter={[8, 8]}>
            {answerList.map((x: any) => {
              if (props.type === 'emoji') {
                return (
                  <Col
                    style={{
                      paddingTop: '10px',
                      paddingBottom: '10px',
                      paddingLeft: '17px',
                      paddingRight: '17px',
                      border: selectedAnswer === x.id ? '1px solid #735ebb' : 'none' 
                    }}
                    onClick={() => handleAnswerSelect(x.id)}
                    className={style.emojiBox}
                    md={24}
                    key={x.id}
                  >
                    {x.icon} {x.title}
                  </Col>
                );
              } else {
                return (
                  <Col
                    md={8}
                    xs={8}
                    onClick={() => handleAnswerSelect(x.id)}
                    key={x.id}
                  >
                    <div
                      className={style.cardBox}
                      style={{
                        paddingTop: '10px',
                        paddingBottom: '10px',
                        paddingLeft: '17px',
                        paddingRight: '17px',
                        border: selectedAnswer === x.id ? '1px solid #735ebb' : 'none' 
                      }}
                    >
                      <div className={style.iconContainer}>
                        <img
                        src={require('../images/' + x.icon + '.svg')}
                        className={style.icons}
                        alt={x.icon}
                      />
                      </div>
                      
                      <div className={style.cardBoxTitle}> {x.title}</div>
                     
                    </div>
                  </Col>
                );
              }
            })}
          </Row>
        </div>
      </div>
    </Row>
  );
}

export default QuestionStep;
