export const questions =[
    {
        id:"0-3 months",
        question:[
            {
                id:1,
                type:'emoji',
                text:"Bebeğiniz kendi kendine sesler çıkarıyor mu?",
                answers:[1,2,3]
            },
            {
                id:2,
                type:'emoji',
                text:"Bebeğiniz emme refleksi gösteriyor ve memeyi rahatça emebiliyor mu?",
                answers:[1,2,3]
            },
            {
                id:3,
                type:'emoji',
                text:"Bebeğinizin uykusu düzenli mi?",
                answers:[1,2,3]
            },
            {
                id:4,
                type:'card',
                text:"Bebeğinizle ilgili en çok hangi konuda zorlanıyorsunuz?",
                answers:[4,5,6,7,8,9]
            }, 
        ]
    },
    {
        id:"3-6 months",
        question:[
            {
                id:1,
                type:'emoji',
                text:"Bebeğiniz gülümsüyor veya gülüyor mu?",
                answers:[1,2,3]
            },
            {
                id:2,
                type:'emoji',
                text:"Bebeğiniz hareket eden sesleri takip edebiliyor mu?",
                answers:[1,2,3]
            },
            {
                id:3,
                type:'emoji',
                text:"Bebeğiniz eline aldığı nesneleri ağzına götürüyor mu?",
                answers:[1,2,3]
            },
            {
                id:4,
                type:'emoji',
                text:"Bebeğinizin uykusu düzenli mi?",
                answers:[1,2,3]
            },
            {
                id:5,
                type:'card',
                text:"Bebeğinizle ilgili en çok hangi konuda zorlanıyorsunuz?",
                answers:[4,5,6,7,8,9]
            },
        ]
    },
    {
        id:"6-9 months",
        question:[
            {
                id:1,
                type:'emoji',
                text:"Bebeğiniz desteksiz oturabiliyor mu?",
                answers:[1,2,3]
            },
            {
                id:2,
                type:'emoji',
                text:"Bebeğiniz emekliyor mu?",
                answers:[1,2,3]
            },
            {
                id:3,
                type:'emoji',
                text:"Bebeğiniz ağzını kullanarak nesneleri keşfetmeye çalışıyor mu?",
                answers:[1,2,3]
            },
            {
                id:4,
                type:'emoji',
                text:"Bebeğinizin uykusu düzenli mi?",
                answers:[1,2,3]
            },
            {
                id:5,
                type:'emoji',
                text:"Bebeğiniz baba veya anneyi gördüğünde sevincini ve heyecanını belli edebiliyor mu?",
                answers:[1,2,3]
            },
            {
                id:6,
                type:'emoji',
                text:"Bebeğiniz göz kontağı kurabiliyor mu?",
                answers:[1,2,3]
            },
            {
                id:7,
                type:'emoji',
                text:"Bebeğiniz ilgi çekmek için sesler çıkarıyor mu?",
                answers:[1,2,3]
            },
            {
                id:8,
                type:'card',
                text:"Bebeğinizle ilgili en çok hangi konuda zorlanıyorsunuz?",
                answers:[4,5,10,7,8,9]
            },
        ]
    },
    {
        id:"9-12 months",
        question:[
            {
                id:1,
                type:'emoji',
                text:"Bebeğiniz oyuncaklarını bilerek düşürüyor ve düşüşlerini izliyor mu?",
                answers:[1,2,3]
            },
            {
                id:2,
                type:'emoji',
                text:"Bebeğinizin uykusu düzenli mi?",
                answers:[1,2,3]
            },
            {
                id:3,
                type:'emoji',
                text:"Bebeğiniz desteksiz olarak yerde uzun süreli oturabiliyor mu?",
                answers:[1,2,3]
            },
            {
                id:4,
                type:'emoji',
                text:"Bebeğiniz kendi ismini biliyor ve seslenildiğinde doğru yöne dönebiliyor mu?",
                answers:[1,2,3]
            },
            {
                id:5,
                type:'emoji',
                text:"Bebeğiniz ayrılırken el sallayabiliyor mu?",
                answers:[1,2,3]
            },
            {
                id:6,
                type:'emoji',
                text:"Bebeğiniz göz kontağı kurabiliyor mu?",
                answers:[1,2,3]
            },
            {
                id:7,
                type:'emoji',
                text:"Bebeğiniz oyuncağını elden ele geçirebiliyor mu?",
                answers:[1,2,3]
            },
            {
                id:7,
                type:'card',
                text:"Bebeğinizle ilgili en çok hangi konuda zorlanıyorsunuz?",
                answers:[4,5,10,7,8,9]
            },
        ]
    },
    {
        id:"12-15 months",
        question:[
            {
                id:1,
                type:'emoji',
                text:"Çocuğunuz oyuncaklarını kutusuna koyabiliyor mu?",
                answers:[1,2,3]
            },
            {
                id:2,
                type:'emoji',
                text:"Çocuğunuzun uykusu düzenli mi?",
                answers:[1,2,3]
            },
            {
                id:3,
                type:'emoji',
                text:"Çocuğunuz farklı dokulara rahatsız olmadan dokunabiliyor mu?",
                answers:[1,2,3]
            },
            {
                id:4,
                type:'emoji',
                text:"Çocuğunuz güle güle anlamında el sallayabiliyor mu?",
                answers:[1,2,3]
            },
            {
                id:5,
                type:'emoji',
                text:"Çocuğunuzun iştahsız olduğunu söyleyebilir misiniz?",
                answers:[1,2,3]
            },
            {
                id:6,
                type:'emoji',
                text:"Çocuğunuz sözel ifade edilen nesneye yönelebiliyor ya da gösterebiliyor mu? (Örneğin burnun, ağzın nerede gibi)",
                answers:[1,2,3]
            },
            {
                id:7,
                type:'card',
                text:"Çocuğunuzla ilgili en çok hangi konuda zorlanıyorsunuz? ",
                answers:[4,5,8,11,12,13]
            },
        ]
    },
    {
        id:"15-18 months",
        question:[
            {
                id:1,
                type:'emoji',
                text:"Çocuğunuz sorulduğunda 5 vücut parçasını gösterebiliyor mu?",
                answers:[1,2,3]
            },
            {
                id:2,
                type:'emoji',
                text:"Çocuğunuz ayakkabısını giyip çıkarabiliyor mu?",
                answers:[1,2,3]
            },
            {
                id:3,
                type:'emoji',
                text:"Çocuğunuzun uykusu düzenli mi?",
                answers:[1,2,3]
            },
            {
                id:4,
                type:'emoji',
                text:"Çocuğunuzun iştahsız olduğunu söyleyebilir misiniz?",
                answers:[1,2,3]
            },
            {
                id:5,
                type:'emoji',
                text:"Çocuğunuz suyunu yardımsız içebiliyor mu?",
                answers:[1,2,3]
            },
            {
                id:6,
                type:'emoji',
                text:"Çocuğunuz bazı hayvan seslerini taklit edebiliyor mu?",
                answers:[1,2,3]
            },
            {
                id:7,
                type:'card',
                text:"Çocuğunuzla ilgili en çok hangi konuda zorlanıyorsunuz?  ",
                answers:[4,5,8,11,12,13]
            },
        ]
    },
    {
        id:"18-21 months",
        question:[
            {
                id:1,
                type:'emoji',
                text:"Çocuğunuz topa tekme atabiliyor mu?",
                answers:[1,2,3]
            },
            {
                id:2,
                type:'emoji',
                text:"Çocuğunuzun uykusu düzenli mi?",
                answers:[1,2,3]
            },
            {
                id:3,
                type:'emoji',
                text:"Çocuğunuz “Ayakkabıyı ver.” gibi basit komutları anlayabiliyor mu?",
                answers:[1,2,3]
            },
            {
                id:4,
                type:'emoji',
                text:"Çocuğunuz tutunarak ya da desteksiz merdiven çıkabiliyor mu?",
                answers:[1,2,3]
            },
            {
                id:5,
                type:'emoji',
                text:"Çocuğunuz kitap içinde yer alan nesneleri ifade etmeye çalışıyor mu?",
                answers:[1,2,3]
            },
            {
                id:6,
                type:'emoji',
                text:"Çocuğunuzun iştahsız olduğunu söyleyebilir misiniz?",
                answers:[1,2,3]
            },
            {
                id:7,
                type:'card',
                text:"Çocuğunuzla ilgili en çok hangi konuda zorlanıyorsunuz?  ",
                answers:[4,5,8,11,12,13]
            },
        ]
    },
    {
        id:"21-24 months",
        question:[
            {
                id:1,
                type:'emoji',
                text:"Çocuğunuz nesneleri boyutlarına göre sıralayabiliyor mu?",
                answers:[1,2,3]
            },
            {
                id:2,
                type:'emoji',
                text:"Çocuğunuzun uykusu düzenli mi?",
                answers:[1,2,3]
            },
            {
                id:3,
                type:'emoji',
                text:"Çocuğunuz kirlenmekten korkmadan boya malzemelerine dokunabiliyor mu?",
                answers:[1,2,3]
            },
            {
                id:4,
                type:'emoji',
                text:"Çocuğunuz 4-6 küpten kule yapabiliyor mu?",
                answers:[1,2,3]
            },
            {
                id:5,
                type:'emoji',
                text:"Çocuğunuzda 2 yaş sendromu belirtileri gözlemliyor musunuz?",
                answers:[1,2,3]
            },
            {
                id:6,
                type:'emoji',
                text:"Çocuğunuz sözlü olarak nesnelerin, hayvanların, kişilerin isimlerini ifade edebiliyor mu?",
                answers:[1,2,3]
            },
            {
                id:7,
                type:'card',
                text:"Çocuğunuzla ilgili en çok hangi konuda zorlanıyorsunuz?  ",
                answers:[4,5,8,11,12,13]
            },
        ]
    },
    {
        id:"24-30 months",
        question:[
            {
                id:1,
                type:'emoji',
                text:"Çocuğunuz rahatsız olmadan sesli oyunları oynayabiliyor mu?",
                answers:[1,2,3]
            },
            {
                id:2,
                type:'emoji',
                text:"Çocuğunuz dağıttıklarını toplayabiliyor mu?",
                answers:[1,2,3]
            },
            {
                id:3,
                type:'emoji',
                text:"Çocuğunuz ayakkabısını tek başına giyip çıkarabiliyor mu?",
                answers:[1,2,3]
            },
            {
                id:4,
                type:'emoji',
                text:"Çocuğunuzun uykusu düzenli mi?",
                answers:[1,2,3]
            },
            {
                id:5,
                type:'emoji',
                text:"Çocuğunuzda 2 yaş sendromu belirtileri gözlemliyor musunuz?",
                answers:[1,2,3]
            },
            {
                id:6,
                type:'emoji',
                text:"Çocuğunuzun iştahsız olduğunu düşünüyor musunuz?",
                answers:[1,2,3]
            },
            {
                id:7,
                type:'emoji',
                text:"Çocuğunuz tuvalet eğitimine hazır olduğuna dair belirti gösteriyor mu?",
                answers:[1,2,3]
            },
            {
                id:8,
                type:'card',
                text:"Çocuğunuzla ilgili en çok hangi konuda zorlanıyorsunuz?  ",
                answers:[5,8,9,11,12,13]
            },
        ]
    },
    {
        id:"30-36 months",
        question:[
            {
                id:1,
                type:'emoji',
                text:"Çocuğunuz tek başına merdiven çıkabiliyor mu?",
                answers:[1,2,3]
            },
            {
                id:2,
                type:'emoji',
                text:"Çocuğunuz her türlü maddeye rahatsız olmadan dokunabiliyor mu?",
                answers:[1,2,3]
            },
            {
                id:3,
                type:'emoji',
                text:"Çocuğunuz kendi kendine oyun oynayabiliyor mu?",
                answers:[1,2,3]
            },
            {
                id:4,
                type:'emoji',
                text:"Çocuğunuz bezi bıraktı mı?",
                answers:[1,2,3]
            },
            {
                id:5,
                type:'emoji',
                text:"Çocuğunuzun uykusu düzenli mi?",
                answers:[1,2,3]
            },
            {
                id:6,
                type:'emoji',
                text:"Çocuğunuzun iştahsız olduğunu düşünüyor musunuz?",
                answers:[1,2,3]
            },
            {
                id:7,
                type:'card',
                text:"Çocuğunuzla ilgili en çok hangi konuda zorlanıyorsunuz?  ",
                answers:[5,8,9,11,12,13]
            },
        ]
    },
    {
        id:"36-48 months",
        question:[
            {
                id:1,
                type:'emoji',
                text:"Çocuğunuz 10’a kadar sayabiliyor mu?",
                answers:[1,2,3]
            },
            {
                id:2,
                type:'emoji',
                text:"Çocuğunuzun uykusu düzenli mi?",
                answers:[1,2,3]
            },
            {
                id:3,
                type:'emoji',
                text:"Çocuğunuz tek ayak üzerinde 5 saniye durabiliyor mu?",
                answers:[1,2,3]
            },
            {
                id:4,
                type:'emoji',
                text:"Çocuğunuz adını, soyadını, cinsiyetini, yaşını söyleyebiliyor mu?",
                answers:[1,2,3]
            },
            {
                id:5,
                type:'emoji',
                text:"Çocuğunuzun konuşmaları anlaşılır mı?",
                answers:[1,2,3]
            },
            {
                id:6,
                type:'emoji',
                text:"Çocuğunuz çatal ve kaşığı ustalıkla kullanabiliyor mu?",
                answers:[1,2,3]
            },
            {
                id:7,
                type:'emoji',
                text:"Çocuğunuz çok hareketli mi?",
                answers:[1,2,3]
            },
            {
                id:8,
                type:'card',
                text:"Çocuğunuzla ilgili en çok hangi konuda zorlanıyorsunuz?  ",
                answers:[5,8,9,11,12,13]
            },
        ]
    },
    {
        id:"48-60 months",
        question:[
            {
                id:1,
                type:'emoji',
                text:"Çocuğunuz nesneleri 1’den 5’e kadar dokunarak sayabiliyor mu?",
                answers:[1,2,3]
            },
            {
                id:2,
                type:'emoji',
                text:"Çocuğunuz giysilerini giyip çıkarabiliyor mu?",
                answers:[1,2,3]
            },
            {
                id:3,
                type:'emoji',
                text:"Çocuğunuzun uykusu düzenli mi?",
                answers:[1,2,3]
            },
            {
                id:4,
                type:'emoji',
                text:"Çocuğunuz tek bir aktiviteyi dikkati dağılmadan 5 dakikadan fazla sürdürebiliyor mu?",
                answers:[1,2,3]
            },
            {
                id:5,
                type:'emoji',
                text:"Çocuğunuzun konuşması tümüyle anlaşılıyor mu?",
                answers:[1,2,3]
            },
            {
                id:6,
                type:'emoji',
                text:"Çocuğunuz çok hareketli mi?",
                answers:[1,2,3]
            },
            {
                id:7,
                type:'emoji',
                text:"Çocuğunuz basit geometrik şekilleri tanıyor ve isimlendirebiliyor mu?",
                answers:[1,2,3]
            },
            {
                id:8,
                type:'card',
                text:"Çocuğunuzla ilgili en çok hangi konuda zorlanıyorsunuz?  ",
                answers:[5,8,9,11,12,13]
            },
        ]
    },
    {
        id:"60-72 months",
        question:[
            {
                id:1,
                type:'emoji',
                text:"Çocuğunuz gözleri kapalıyken vücudunda nereye dokunduğunuzu söyleyebilir mi?",
                answers:[1,2,3]
            },
            {
                id:2,
                type:'emoji',
                text:"Çocuğunuz arkadaşlarıyla uyum içinde oynuyor mu?",
                answers:[1,2,3]
            },
            {
                id:3,
                type:'emoji',
                text:"Çocuğunuz yemeğini dökmeden yiyebiliyor mu?",
                answers:[1,2,3]
            },
            {
                id:4,
                type:'emoji',
                text:"Çocuğunuzun uykusu düzenli mi?",
                answers:[1,2,3]
            },
            {
                id:5,
                type:'emoji',
                text:"Çocuğunuz tek bir aktiviteyi dikkati dağılmadan 5 dakikadan fazla sürdürebiliyor mu?",
                answers:[1,2,3]
            },
            {
                id:6,
                type:'emoji',
                text:"Çocuğunuz çok hareketli mi?",
                answers:[1,2,3]
            },
            {
                id:7,
                type:'emoji',
                text:"Çocuğunuz harf ve şekilleri kopyalayabiliyor mu?",
                answers:[1,2,3]
            },
            {
                id:8,
                type:'emoji',
                text:"Çocuğunuzun sağ veya sol el kullanım tercihi var mı?",
                answers:[1,2,3]
            },
            {
                id:9,
                type:'card',
                text:"Çocuğunuzla ilgili en çok hangi konuda zorlanıyorsunuz?  ",
                answers:[5,8,9,11,12,13]
            },
        ]
    },
    
]
export const  answers = [
    {
        id:1,
        icon:'😊',
        title:"Evet"
    },
    {
        id:2,
        icon:'😕',
        title:"Hayır"
    },
    {
        id:3,
        icon:'🤔',
        title:"Emin Değilim"
    },
    {
        id:4,
        icon:'emzirme',
        title:"Emzirme"
    },
    {
        id:5,
        icon:'uyku',
        title:"Uyku"
    },
    {
        id:6,
        icon:'growth',
        title:"Rutin Oluşturma"
    },
    {
        id:7,
        icon:'oyun',
        title:"Aktivite"
    },
    {
        id:8,
        icon:'gelisim',
        title:"Gelişim Takibi"
    },
    {
        id:9,
        icon:'health',
        title:"Sağlık"
    },
    {
        id:10,
        icon:'beslenme',
        title:"Ek Gıda"
    },
    {
        id:11,
        icon:'beslenme',
        title:"Beslenme"
    },
    {
        id:12,
        icon:'davranis',
        title:"Davranışlar"
    },
    {
        id:13,
        icon:'oyun',
        title:"Oyun ve Aktivite "
    },
]
export const results =[
    {
        id:4,
        title:"Emzirme",
        desc:"Emzirme ile ilgili danışmak istediklerinizi yanıtlamak üzere Çocuk Sağlığı ve Hastalıkları Uzmanı Kidokit’te! Diğer tüm konularla ilgili uzman ekibimize danışabilirsiniz. Hemen indirin!"
    },
    {
        id:5,
        title:"Uyku",
        desc: "Çocuk Sağlığı ve Hastalıkları Uzmanımıza uyku hakkındaki sorularınızı sormak için hemen Kidokit’i indirin!"
    },
    {
        id:6,
        title:"Rutin oluşturma",
        desc:"Çocuk Sağlığı ve Hastalıkları Uzmanımıza rutin oluşturma hakkındaki sorularınızı sormak için hemen Kidokit’i indirin!"
    },{
        id:7,
        title:"Aktivite",
        desc:"1000’den fazla oyun ve aktivite videosu Kidokit’te. Günlük planınıza hemen başlamak için Kidokit’i indirin!"
    },{
        id:8,
        title:"Gelişim takibi",
        desc:"Kidokit ile gelişim takibi çok kolay! Uzmanlarımız tarafından hazırlanmış oyun ve aktivite videoları, gelişim takibi soruları ve başka hiçbir yerde bulamayacağınız Uzman değerlendirme soruları için hemen Kidokit’i indirin!"
    },{
        id:9,
        title:"Sağlık",
        desc:"Çocuk Sağlığı ve Hastalıkları Uzmanımız sorularınızı yanıtlıyor! Hemen Kidokit’i indirin, doktora sorularınızı iletin."
    },{
        id:10,
        title:"Ek gıda",
        desc:"Ek gıda ile ilgili danışmak istediklerinizi yanıtlamak üzere Çocuk Sağlığı ve Hastalıkları Uzmanı Kidokit’te! Diğer tüm konularla ilgili uzman ekibimize danışabilirsiniz. Hemen indirin!"
    },
    {
        id:11,
        title:"Beslenme",
        desc:"Çocuk Sağlığı ve Hastalıkları Uzmanımıza beslenme hakkındaki sorularınızı sormak için hemen Kidokit’i indirin!"
    },
    {
        id:12,
        title:"Davranışlar",
        desc:"Uzmana sor özelliği Kidokit’te! Çocuğunuzun davranışları ile ilgili uzmanlarımıza soru sormak için hemen Kidokit’i indirin!"
    },
    {
        id:13,
        title:"Oyun ve aktivite",
        desc:"1000’den fazla oyun ve aktivite videosu Kidokit’te. Günlük planınıza hemen başlamak için Kidokit’i indirin!"
    },
    
]