import React, { useEffect, useState } from 'react';
import logo from "./images/Logo.svg";
import style from './app.module.scss'
import StepOne from './page-parts/step-one';
import QuestionStep from './page-parts/question-step';
import ResultStep from './page-parts/result-step';
import { questions, results } from './data/data';
import { Spin } from 'antd';
import TagManager, { TagManagerArgs } from 'react-gtm-module'; 

const IndexPage = () => {
  const [dateRange, setDateRange] = useState<string | undefined>();
  const [resultId, setResultId] = useState<number | undefined>();
  const [showStepOne, setShowStepOne] = useState(true);
  const [questionList, setQuestionList] = useState<any[]>([]);
  const [questionsCompleted, setQuestionsCompleted] = useState(false);

  const handleClickOutside = (range: string | undefined) => {
    setDateRange(range);
    setShowStepOne(false);
    updateQuestionList(range);
  };

  const updateQuestionList = (range: string | undefined) => {
    const filteredQuestions = questions
      .filter((x) => x.id === range)
      .map((x) => x.question)
      .flat();
    setQuestionList(filteredQuestions);
  };
  
  useEffect(() => {
    const tagManagerArgs: TagManagerArgs = {
      gtmId: 'GTM-W23QH4L3'
    };

    TagManager.initialize(tagManagerArgs);
  }, []);
  useEffect(() => {
    const filteredQuestions = questions
      .filter((x) => x.id === dateRange)
      .map((x) => x.question)
      .flat();
    setQuestionList(filteredQuestions);
  }, [dateRange]);
  useEffect(() => {
    if (questionList.length > 0) {
      const percent = 100 / questionList.length;
      setCurrentPercentage(percent);
    }
  }, [questionList]);
  
  const percent = 100 / questionList.length;
  const [currentStep, setCurrentStep] = useState(0);
  const [currentPercentage, setCurrentPercentage] = useState(percent);
  const [showSpinner, setShowSpinner] = useState(false); // New state for showing the spinner
  const [showResult, setShowResult] = useState(false); // New state for showing the result

  useEffect(() => {
    if (questionsCompleted) {
      setShowSpinner(true); // Show the spinner when questions are completed
      setTimeout(() => {
        setShowSpinner(false); // Hide the spinner after 3 seconds
        setShowResult(true); // Show the result
      }, 3000);
    }
  }, [questionsCompleted]);
  const handleNextStep = (answerId: number) => {
    if (currentStep === questionList.length - 1) {
      setQuestionsCompleted(true);
      setResultId(answerId); // Update resultId with the last answer's ID
    } else {
      setCurrentStep((prevStep) => prevStep + 1);
  
      // Calculate the current percentage dynamically based on the current step and total steps
      const calculatedPercentage = ((currentStep + 2) / questionList.length) * 100;
  
      setCurrentPercentage(calculatedPercentage);
    }
  };

  return (
    <div className={style.main}>
      <header className={style.header}>
        <img src={logo} className={style.AppLogo} alt="logo" /> 
      </header>
      <div className={style.content}>
        {showStepOne && <StepOne onContiueClick={handleClickOutside} />}
        {!showStepOne && !questionsCompleted && questionList && questionList.length > 0 && currentStep < questionList.length && (
          <QuestionStep
            type={questionList[currentStep]?.type}
            question={questionList[currentStep]?.text}
            percent={currentPercentage}
            step={(currentStep + 2).toString()}
            id={0}
            answers={questionList[currentStep]?.answers}
            {...questionList[currentStep]}
            onAnswerSelect={handleNextStep}
          />
        )}
        {showSpinner && !showResult && (
          <div className={style.spinnerContainer}>
            <Spin tip='Günlük Plan Hazırlanıyor…' size="large">
              <div className="content" />
            </Spin> 
          </div>
        )}

        {/* Show the result after the spinner */}
        {showResult && questionsCompleted && (
          <ResultStep result={results.find((result) => result.id === resultId)} />
        )}
      </div>
    </div>
  );
}

export default IndexPage;
