import React, { useState } from "react";

import style from  "./float-label.module.scss";

interface FloatLabelProps {
    label: string | undefined;
    value: string | undefined;
    children?:any
  }
  
const FloatLabel = (props:FloatLabelProps) => {
  const [focus, setFocus] = useState(false);
  

  const labelClass =
    focus || (props.value && props.value.length !== 0) ? style.label +" "+ style.labelFloat : style.label;

  return (
    <div
      className={style.floatLabel}
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
    >
      {props.children}
      <label className={labelClass}>{props.label}</label>
    </div>
  );
};

export default FloatLabel;
