/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Progress, Row, Select, message } from 'antd';
import React, { useState } from 'react';
import PrimaryButton from '../components/button';
import FloatLabel from '../components/float-label';
import style from './step-one.module.scss';

const { Option } = Select;
interface stepOneProps{
  onContiueClick: (dateRange:string|undefined) => void;
}
function StepOne(props:stepOneProps) {
  const [selectedDay, setSelectedDay] = useState<string | undefined>(undefined);
  const [selectedMonth, setSelectedMonth] = useState<string | undefined>(undefined);
  const [selectedYear, setSelectedYear] = useState<string | undefined>(undefined);

  const handleDayChange = (value: string) => {
    setSelectedDay(value);
  };

  const handleMonthChange = (value: string) => {
    setSelectedMonth(value);
  };

  const handleYearChange = (value: string) => {
    setSelectedYear(value);
  };

  const monthNames = [
    'Ocak',
    'Şubat',
    'Mart',
    'Nisan',
    'Mayıs',
    'Haziran',
    'Temmuz',
    'Ağustos',
    'Eylül',
    'Ekim',
    'Kasım',
    'Aralık',
  ];

  const currentYear = new Date().getFullYear();
  const yearOptions = Array.from({ length: 7 }, (_, index) => currentYear - index);
  const calculateAgeInMonths = (currentDate: Date, selectedDate: Date): number => {
    const yearDiff = currentDate.getFullYear() - selectedDate.getFullYear();
    const monthDiff = currentDate.getMonth() - selectedDate.getMonth();
    const dayDiff = currentDate.getDate() - selectedDate.getDate();

    let ageInMonths = yearDiff * 12 + monthDiff;
    if (dayDiff < 0) {
      ageInMonths--;
    }

    return ageInMonths;
  };

  const getAgeRange = (ageInMonths: number): string => {
    if (ageInMonths >= 0 && ageInMonths <= 3) {
      return '0-3 months';
    } else if (ageInMonths > 3 && ageInMonths <= 6) {
      return '3-6 months';
    } else if (ageInMonths > 6 && ageInMonths <= 9) {
      return '6-9 months';
    }else if (ageInMonths > 9 && ageInMonths <= 12) {
      return '9-12 months';
    }else if (ageInMonths > 12 && ageInMonths <= 15) {
      return '12-15 months';
    }else if (ageInMonths > 15 && ageInMonths <= 18) {
      return '15-18 months';
    }else if (ageInMonths > 18 && ageInMonths <= 21) {
      return '18-21 months';
    }else if (ageInMonths > 21 && ageInMonths <= 24) {
      return '21-24 months';
    }else if (ageInMonths > 24 && ageInMonths <= 30) {
      return '24-30 months';
    }else if (ageInMonths > 30 && ageInMonths <= 36) {
      return '30-36 months';
    }else if (ageInMonths > 36 && ageInMonths <=48) {
      return '36-48 months';
    }else if (ageInMonths > 48 && ageInMonths <= 60) {
      return '48-60 months';
    }else if (ageInMonths > 60 && ageInMonths <= 72) {
      return '60-72 months';
    }else {
      return 'Unknown';
    }
  };
  const calculateAgeRange = () => {
    if (selectedDay && selectedMonth && selectedYear) {
      const currentDate = new Date();
      const selectedDate = new Date(
        Number(selectedYear),
        Number(selectedMonth) - 1,
        Number(selectedDay)
      );

      if (selectedDate > currentDate) {
        message.error('Seçilen tarih gelecekte olamaz.');
        return;
      }

      if (selectedDate < new Date(currentYear - 6, new Date().getMonth(), new Date().getDate())) {
        message.error('Seçilen tarih 6 yıldan daha eski olamaz.');
        return;
      }

      const ageInMonths = calculateAgeInMonths(currentDate, selectedDate);
      const range = getAgeRange(ageInMonths);
      props.onContiueClick(range);
    } else {
      message.error('Lütfen Geçerli Bir Tarih Giriniz.');
    }
  };

  return (
    <Row gutter={[24, 24]} className={style.container}>
      <Progress
        className={style.steps}
        style={{ backgroundColor: '#fafafa', borderRadius: '50%', width: '79px', height: '79px' }}
        width={80}
        type="circle"
        percent={0}
        format={() => (
          <div className={style.customProgress}>
            <span>1</span>
          </div>
        )}
        strokeColor="#735EBB"
        strokeWidth={6}
        trailColor="#fafafa"
      />
      <div className={style.questionContainer}>
        <span>Çocuğunuzun doğum tarihini seçerek başlayın.</span>
      </div>
      <div className={style.birthdayContainer}>
        <div className={style.content}>
          <Row className={style.birthday} style={{ flex: 1 }}>
            <Col span={24}>
              <p className={style.birthdate}> Doğum Tarihi</p>
              <Row className={style.date} gutter={[8, 16]}>
                <Col md={8} xs={8}>
                  <FloatLabel label={'Gün'} value={selectedDay}>
                    <Select
                      allowClear
                      bordered={false}
                      className={style.dateSelector}
                      value={selectedDay}
                      onClear={()=> setSelectedDay(undefined)}
                      onChange={handleDayChange}
                    >
                      {Array.from({ length: 31 }, (_, index) => index + 1).map((day) => (
                        <Option key={day} value={day.toString()}>
                          {day}
                        </Option>
                      ))}
                    </Select>
                  </FloatLabel>
                </Col>
                <Col md={8} xs={8}>
                  <FloatLabel label={'Ay'} value={selectedMonth}>
                    <Select
                      allowClear
                      bordered={false}
                      className={style.dateSelector}
                      value={selectedMonth}
                      onChange={handleMonthChange}
                      onClear={()=> setSelectedMonth(undefined)}
                    >
                      {monthNames.map((month, index) => (
                        <Option key={index} value={(index + 1).toString()}>
                          {month}
                        </Option>
                      ))}
                    </Select>
                  </FloatLabel>
                </Col>
                <Col md={8} xs={8}>
                  <FloatLabel label={'Yıl'} value={selectedYear}>
                    <Select
                      allowClear
                      bordered={false}
                      className={style.dateSelector}
                      value={selectedYear}
                      onChange={handleYearChange}
                      onClear={()=> setSelectedYear(undefined)}
                    >
                      {yearOptions.map((year) => (
                        <Option key={year} value={year.toString()}>
                          {year}
                        </Option>
                      ))}
                    </Select>
                  </FloatLabel>
                </Col>
              </Row>
            </Col>
            <Col className="flex-center-f">
              <Col md={12} xs={24}>
                <PrimaryButton onClick={calculateAgeRange}>Devam Et</PrimaryButton>
              </Col>
            </Col>
          </Row>
          
        </div>
      </div>
    </Row>
  );
}

export default StepOne;
