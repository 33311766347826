import { Button, ButtonProps } from "antd";
import style from './button.module.scss'

const PrimaryButton =(props: ButtonProps) => {
    return(
        <Button {...props} className={style.primaryButton}>
            {props.children}
        </Button>
    )
}
 export default PrimaryButton;