import { Col, Row } from 'antd';
import style from './result-step.module.scss';
import party from "../images/objects.svg";
import check from "../images/check.svg";
import PrimaryButton from '../components/button';


interface ResultStepProps {
  result?:any
}
const handleButtonClick = (event: React.MouseEvent<HTMLElement>) => {
  event.preventDefault();
  const url = 'https://app.adjust.com/19u2wf0q';
  window.open(url, '_blank');
};

function ResultStep(props: ResultStepProps) {

  const highlightKidokit = (text:string) => {
    const phrasesToHighlight = [
      "Çocuk Sağlığı ve Hastalıkları Uzmanı Kidokit’te",
      "Çocuk Sağlığı ve Hastalıkları Uzmanımıza",
      "1000’den fazla oyun ve aktivite videosu",
      "oyun ve aktivite videoları, gelişim takibi soruları",
      "Uzman değerlendirme soruları",
      "Uzmana sor özelliği Kidokit’te",
      "doktora sorularınızı iletin."
    ];

    let newText = text;
    phrasesToHighlight.forEach(phrase => {
      const regex = new RegExp(`(${phrase})`, 'gi');
      newText = newText.replace(regex, '<b>$1</b>');
    });

    return <span dangerouslySetInnerHTML={{ __html: newText }} />;
  };

  return (
    <Row gutter={[24, 24]} className={style.container}>
      <div className={style.resultContainer}>
        <Row  className={style.resultInner}gutter={[24, 0]}>
          <Col>
          <img src={party} className={style.party} alt="party" /> 
          </Col>
          <Col>
            <p>Çocuğunuza Özel <br/> Günlük Gelişim Planı Hazır.  </p>
          </Col>
          <Col className={style.desc}>
            <Row >
            <Col span={24} className={style.text}>
                  <img src={check}  alt="party" /> <span>{highlightKidokit(props.result.desc)}</span>
              </Col>
              <Col span={24}className={style.text}>
                  <img src={check}  alt="party" /><span><b>Günlük Plan</b></span>
              </Col>
              <Col span={24}className={style.text}>
                  <img src={check}  alt="party" /><span>1000+ <b> oyun ve aktivite </b> videoları</span>
              </Col>
              <Col span={24} className={style.text}>
                 <img src={check}  alt="party" /> <span>0-3 yaş arası her hafta <b> Haftalık Makale</b></span>
              </Col>
              <Col span={24} className={style.text}> 
                  <img src={check}  alt="party" /><span> 3-6 yaş arası her ay  <b> Aylık Makale</b></span>
              </Col>
              <Col span={24} className={style.text}>
                 <img src={check}  alt="party" /><span><b>Uzman değerlendirme videoları </b> </span>

              </Col>
              <Col>ve daha fazlası...</Col>
            </Row>
            
          </Col>
          <Col className={style.qr}><img alt='kidokit-qr' src={require('../images/quiz.png')} ></img></Col>
          <Col className={style.button}> <PrimaryButton onClick={handleButtonClick}>Hemen İndir</PrimaryButton></Col>
        </Row>
        <span></span>
      </div>
    </Row>
  );
}

export default ResultStep;
